@import '../../../assets/styles/variables';

.SilencePeriodHead {
  height: auto;
}
.SilencePeriodHeadBackround {
  position: relative;
  top: 38px;
  left: 0;
  height: 50%;
  width: 50%;
  min-height: 35vh;
  min-width: 40vh;
 // object-fit: cover;
 // @media screen and (max-height: 567px) {
 //   height: 25vh;
 // }
}
.BookingHeadBackround {
  position: relative;
  top: 38px;
  left: 0;
  height: 35vh;
  width: 100%;
  // object-fit: cover;
  @media screen and (max-height: 567px) {
    height: 25vh;
  }

}



.SilencePeriodHeadCard {
  background: $surface;
  z-index: 3;
  position: absolute;
  box-shadow: 0 4px 4px 0 $shadow;
  top: 1em;
  padding: 1em 3em;
  @media screen and (max-height: 567px) {
    padding: 0.5em 3em;
    top: 0.3em;

  }
}
.SilencePeriodHeadCard::after {
  content: "";

  width: 20px;
  height: 20px;
  background: $surface;
  transform: rotate(45deg); /* Prefixes... */
  top: calc(100% - 10px);
  left: calc(50% - 10px);
  box-shadow: 0 1px 4px 0 $shadow;

  position: absolute;
  z-index: -1;
}
.SilencePeriodName {
  font-family: 'BebasNeue-Regular' !important;
  font-size: 2em !important;
  @media screen and (max-height: 567px) {
    font-size: 1.8em
  }
}

