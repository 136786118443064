@import '../../assets/styles/variables';

.settingsContainer {
  top: 70px;
  // height: calc(100vh - 186px);
  background: $background;
  width: 100vw;
}

.settingsContainerListItem {
  height: 52px;
  background: $surface !important;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
}

.settingsListItemTitle {
  font-size: 1em !important;
  font-family: 'Inter-UI-Regular';
}

.button-edit-margin {
  margin-right: 0px !important;
}

.button-edit-disabled {
  // border: 1px solid $borders !important;
  color: white !important;
  background: $borders !important;
}
