.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 26px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;


  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: rgba(58, 77, 143, 0.5);
  border-radius: 26px;
  transition: 0.2s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 2px;
  bottom: 1px;
 // top: 4px;
  background-color: #FFFFFF;
  border-radius: 26px;
  transition: 0.4s;
}

input:checked + .slider{
  background-color: #3A4D8F;
}

input:checked + .slider:before {
    transform: translateX(17px);
  }


.sliderOff {
  position: absolute;
  cursor: pointer;


  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: #F2F2F2;
  border-radius: 26px;
 // transition: 0.2s;
}

.sliderOff:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 2px;
  bottom: 1px;
  // top: 4px;
  background-color: #FFFFFF;
  border-radius: 26px;
  //transition: 0.4s;
}

