@import "../../../assets/styles/variables";


.blue-btn {
  background: #3A4D8F !important;
  color: $surface !important;
  border-radius: 20px !important;
  padding: 3px !important;
}

.red-btn {
  background: #FF8B79  !important;
  color: $surface !important;
  border-radius: 20px !important;
  padding: 3px !important;
}