
@import "../../../assets/styles/variables";

.users-header {
 width: 100%;
}
.ProfileIcon {
  width: 72px !important;
  height: 72px !important;
  margin-top: -40px;
}
.spacesWrap {
  overflow: hidden;
  height: 100vh;
}
.spacesAssingContainer {
  top: 0px;
  height: calc(100vh - 200px);
  overflow: scroll;
}
.ProfileName {
  font-family: "AlrightSans-Bold";
  color: $secondary;
  font-size: 18px;
  line-height: 22px;
  font-weight: normal;
  text-align: center;
  margin-top: 1em;
}
.ProfileDetails {
  font-family: 'Inter-UI-Regular';
  font-size: 0.85rem;
  margin-top: 1em;
  text-align: center;
  margin-bottom: 1.95em;
}
.ProfileContainer {
  position: relative;
  top: 70px;
  background: $background;
} 
.reportPanelContainer{
  background: $background !important;
  padding: 0 16px;
}
.reportPanelTitle{
  font-weight: bold !important;
  font-size: 16px !important;
}
.spaceAroundExpanded{
  justify-content: space-around !important;
}
//.h3 {
//    font-family: 'Roboto-Bold';
//    font-size: 1.25rem ;
//     color: #3A4D8F;
//    font-weight: 600;
//}