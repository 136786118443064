.UsersList {
  top: 75px;
  height: calc(100vh - 122px);
  position: relative;
  .MuiListItem-container{
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.05) !important;
  }
}
.snackbar-test {
  bottom: 500px !important;
}