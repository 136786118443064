
@import "../../../assets/styles/variables";

.SpaceBlock {
  padding: 0.7em;
}
.container-list {
  position: relative;
  top: 70px;
  background: $background;
} 