@import '../../../../assets/styles/variables';

.ExchangeItemContainer {
  height: 100%;
  min-height: 88px;
  background: $surface !important;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.05);
}
.ExchangeTimeBlock {
  margin: 0 1em 0 0 ;
  vertical-align: top;
}
.ExchangeTimeText {
  background: $background;
  margin: 0.5em 0 !important;
  font-size: 0.75em;
  padding: 0.25em 0.75em;
  font-family: 'Inter-UI-Bold';
  white-space: nowrap;
}
.ExchangeSpaceTitle {
  font-size: 2em;
  font-family: 'BebasNeue-Regular';
  display: flex;
    max-height: 32px;
}
.ExchangeSpaceSubtitle {
  font-size: 0.875 !important;
  font-family: 'Inter-UI-Regular' !important;
}
.item-shadow {
  height: 72px;
  background: $surface !important;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.05);
}
.changeBtn {
  margin-right: 1em !important; 
  border: 1px solid $borders !important;
  display: flex;
  align-items: center;
  border-radius: 12px !important;
  justify-content: center;
  padding: 0 !important;
  width: 25px;
  height: 25px;
  .changeBtn-text {
    color: $text-secondary;
    text-transform: none;
    font-family: 'Inter-UI-Regular';
    font-size: 0.75em;
  }
}

.delete-btn {
  border: 1px solid $borders !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  width: 25px;
  height: 25px;
}


.actionBtn {
  border: 1px solid $secondary !important;
  box-shadow: grey;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  border-radius: 5px !important;
  justify-content: center;
  padding: 0 !important;
  width: 25px;
  height: 25px;
  min-width: 60px !important;
  color: $secondary !important;
  .shareBtn-text {
     color: $secondary;
    text-transform: none;
    font-family: 'Inter-UI-Regular';
    font-size: 0.75em;
  }
}

.actionBtn-disabled {
  border: 1px solid $borders !important;
  display: flex;
  align-items: center;
  border-radius: 12px !important;
  justify-content: center;
  padding: 0 !important;
  width: 25px;
  height: 25px;
  min-width: 60px !important;
  color: white !important;
  background: $borders !important;
  .shareBtn-text {
     color: $secondary;
    text-transform: none;
    font-family: 'Inter-UI-Regular';
    font-size: 0.75em;
  }
}

.pb-10{
  padding-bottom: 8px;
}