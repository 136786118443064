
@import "../../../assets/styles/variables";

.form-container {
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.05);
  padding-top: 88px;
  padding-bottom: 1em;
  background: $surface;
}

.bottom-bar {
  top: auto !important;
  bottom: 0 !important;
  padding: 16px 0;
}

.UserFormSnack {
  bottom: 100px !important;
}