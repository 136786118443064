.switchSm {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 10px;
  right: 10px;
}

.switchSm input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sliderSm {
  position: absolute;
  cursor: pointer;


  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: rgba(58, 77, 143, 0.5);
  border-radius: 8px;
  transition: 0.2s;
}

.sliderSm:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
 // left: 2px;
  bottom: 1px;
  top: -3px;
  background-color: #27348A;
  border-radius: 15px;
  border-width: 1px;
  border-color: #FFFFFF;
  transition: 0.4s;
}

input:checked + .sliderSm{
 // background-color: #3A4D8F;
  background-color: rgba(58, 77, 143, 0.5);

}

input:checked + .sliderSm:before {
    transform: translateX(12px);
  }

