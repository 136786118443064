@import '../../../assets/styles/variables';

.monthlyReportContainer{
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.05);
    position: relative;
    top: 54px;
    padding-bottom: 1em;
    padding: 1em;
    background: $surface;

    .MuiFormControl-root{
        width: 100%;
        height: 40px;
        // margin: 1em 0;
        // margin-bottom: 1em;
        .MuiInputBase-formControl{
            width: 100%;
            height: 40px;
            border: 1px solid #E3E3E3;
            box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
            justify-content: space-between;
            
            .MuiInputBase-input{
                align-items: center;
                &:before {
                    border-bottom: none;
                }
                &:after {
                    border-bottom: none;
                }
                height: 40px;
                width: 100%;
                padding-left: 0.75em;
                color: $text-color;
            }
        }
    }
    .input__text{
        padding-left: 0 !important;
    }
}

.monthlyReportContainer .input{
    border: none !important;
}
.Mui-checked{
    color: #27348A !important;
}

.report_setting_icon{
    text-align: end;
    margin-top: 7px !important;
}