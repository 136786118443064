.territoryNewContainer{
    top: 50px;
    position:relative;
}

.territoryNewContainer .MuiOutlinedInput-input{
    padding: 8.5px 14px !important;
}

.territoryNewContainer .MuiInputLabel-outlined{
    transform: translate(14px, 10px) scale(1);
}

.territoryNewContainer .MuiFormControl-root{
    padding-bottom: 15px;
    padding-right: 15px;
}

.adm_form-container {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
    padding-top: 58px;
    padding-bottom: 1em;
    background: #FFFFFF;
}