@import '../../../assets/styles/variables';
.ExchageContainer {
  position: absolute;
  background: $background;
  min-width: 100%;
  height: 100vd -20vd;
}
.BookingFormContainer {
  bottom: 0;
  //position: absolute;
  top: 15vh;
 // padding: 0.5em;
 // width: calc(100vw - 1em);
}
.SharedWithContainer {
  list-style: none;
  margin-top: -24px !important;

}
.BookingHeadContainer {
  height: calc(55vh - 56px);
  overflow: hidden;
}
.BookingFormBtn {
  position: absolute;
  bottom: 1em;
  max-width: calc(100% - 8px);
}

#UsersDialogContainer{
  top: 0;
  height: calc(100% - 8px);
}
.MuiCircularProgress-colorSecondary {
  color: white !important;
}