@import '../subscription-list/item/SubscriptionItem.scss';

.SubscriptionItemContainerExtended{
    height: 100px;
    align-items: flex-start;
}

.bookedUserName{
    background: #F7F7F7;
    padding: 3px 5px;
    font-size: 0.75em;
    display: inline-block;
    font-weight: bold;
    margin: 5px 0;
    text-align: right;
}