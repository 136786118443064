@import '../../../assets/styles/variables';
.ExchageContainer {
  position: absolute;
  background: $background;
  min-width: 100%;
  height: 100vd -20vd;
}
.ExchageFormContainer {
  bottom: 0;
  //position: relative;
  top: 15vh;
///  padding: 0.5em;
//  width: calc(100vw - 1em);
}
.UsersDialogContainer {
  position: relative;
  top: 20px;
}
.SharedWithContainer {
 // list-style: none;
  margin-top: 0px !important;
 // padding-left: 30px !important;

}
.CheckBoxContainer{
  display: flex;
  padding-bottom: 10px !important;
}
.CheckBoxComponent{
  padding: 0 10px !important;
}
.bottomButton{
  width: 100%;
  position: fixed;
  bottom: 0;
}