@import "../../../assets/styles/variables";

.DashboardItemContainer {
    height: 100%;
    // min-height: 80px;
    background: $surface !important;
   // box-shadow: 0 1px 4px 0 rgba(0,0,0,0.05);
    // padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
