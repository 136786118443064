@import "../../../assets/styles/variables";

.territoryContainer{
    top: 50px;
    position:relative;
}

.heading{
    color: #27348A;
    font-size: 18px !important;
    line-height: 22px !important;
    font-weight: bold !important; 
}

.summary {
    box-shadow: 0 3px 5px 2px rgba(0,0,0,0.12);
    border: 1px solid rgba(0,0,0,0.12) !important;
}  

.territoryContainer .Mui-expanded{
    box-shadow: none !important;
    border-bottom: none !important;
    background: $surface !important;
    
}

.territoryContainer .MuiCollapse-entered{
    border-bottom: 1px solid rgba(0,0,0,0.12) !important;
    border-left: 1px solid rgba(0,0,0,0.12) !important;
    border-right: 1px solid rgba(0,0,0,0.12) !important;
    box-shadow: 0 3px 5px 2px rgba(0,0,0,0.12);
}

.territoryContainer .territoryLotsListItem{
    height: 88px;
    background: $surface !important;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.05);
   
}

.territoryContainer .MuiList-padding{
    padding-top: 0px;
    padding-bottom: 0px;
}

.territoryContainer .territory-form-container{
    padding-bottom: 1em;
    background: $surface;
}

.territoryContainer .MuiAppBar-positionFixed{
    position: inherit;
}

.territoryContainer .MuiOutlinedInput-input{
    padding: 8.5px 14px !important;
}

.territoryContainer .MuiInputLabel-outlined{
    transform: translate(14px, 10px) scale(1);
}

.territoryContainer .MuiFormControl-root{
    padding-bottom: 15px;
    padding-right: 15px;
}

.MuiExpansionPanelSummary-content {
    justify-content: space-between  !important;
}

.territoryContainer .expansionIcon{
    padding-top: 0px;
}

