@import '../../../../assets/styles/variables';

.ListHead {
  height: 51px;
}
.ListHead-small {
  margin: 1em;
  height: 24px;
}
.Title-small {
  font-size: 0.875em;
  color: $text-secondary;
}

//.add-btn {
//  border: 1px solid;
//  borderColor: $secondary !important;
//  display: flex;
//  background: $secondary !important;
//  align-items: center;
//  justify-content: center;
//  padding: 0 !important;
//  width: 25px;
//  height: 25px;
//}