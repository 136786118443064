@import '../../../../assets/styles/variables';

.SpaceHead {
  height: auto;
}
.SpaceHeadBackround {

  position: relative;
  //top: 10vh;
  left: 0;
  height: 20vh;
  width: 100%;
 // object-fit: cover;
  @media screen and (max-height: 567px) {
    height: 20vh;
  }
}
.SpaceHeadCard {
  background: $surface;
  opacity: 0.5;
  justify-content: center;
  align-items: center;
  z-index: 3;
  position: absolute;
  box-shadow: 0 4px 4px 0 $shadow;
  top: 3em;
  padding: 1em 3em;
  @media screen and (max-height: 567px) {
    padding: 0.5em 3em;
    top: 0.3em;

  }
}
.SpaceHeadCard::after {
  content: "";

  width: 20px;
  height: 20px;
  background: $surface;
  transform: rotate(45deg); /* Prefixes... */
  top: calc(100% - 10px);
  left: calc(50% - 10px);
  box-shadow: 0 1px 4px 0 $shadow;

  position: absolute;
  z-index: -1;
}
.SpaceName {
  font-family: 'BebasNeue-Regular' !important;
  font-size: 2em !important;
  @media screen and (max-height: 567px) {
    font-size: 1.8em
  }
}

