@import '../../../../assets/styles/variables';

.form-controll {
  margin: 1em 0 0 0;
  width: 100%;
}
.form-controll-select {
  margin: 1em 0;
  width: 97%;
  position: relative;

}
.input {
  height: 40px;	
  width: 97%;
  margin: 1em 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $borders;
  background-color: $surface;
  box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.1);
}
.input-label {
  transform: translate(2px, -6px) scale(0.8125) !important;
  transform-origin: top left;
  z-index: 0;

}

.input__text {
  width: 100%;
  color: $text-color;
}