@import '../../../../assets/styles/variables';

.SpaceReassignContainerBtn{
    bottom: 1em;
    max-width: calc(100% - 8px);
    padding-top: 1em;
}

.SpaceReassignContainerChek{
    display: flex;
}
.SpaceReassignContainer {
    position: relative;
    // top: 80px;
    background: $background;

    .item {
        height: inherit !important;
    }
  } 