@import '../../../assets/styles/variables';

.DateTimeSettingFormContainer {
  // display: flex;
  //justify-content: space-around;
}
.timePicker{
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #656D78;
  text-align: center;
  align-items: center;
  padding-right: 4em;
  padding-left: 4em;
}
.timePickerActive{
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #27348A;
  text-align: center;
  align-items: center;
  padding-right: 0.1em;
  padding-left: 0.1em;
}

.MuiInput-underline:before {
  border-bottom: 0px solid #3A4D8F !important;
}
.MuiInput-underline:after {
  border-bottom: 0px solid #3A4D8F !important;
}


.MuiInput-input {
  text-align: center !important;
  color: #3A4D8F !important;
  font-family: 'Roboto-Bold' !important ;
  font-size: 1rem !important ;
  color: #3B4754;
  font-weight: 500;
}