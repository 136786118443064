@import "../../../assets/styles/variables";

.lot-form-container{
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
    padding-top: 28px;
    padding-bottom: 1em;
    background: #FFFFFF;
}

.territoryLotsContainer .territoryLotsListItem{
    height: 88px;
    background: $surface !important;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.05);
   
}

.lotContainer{
    top: 150px;
    position:relative;
}

.lotContainer .Mui-expanded{
    box-shadow: none !important;
    border-bottom: none !important;
    background: $surface !important;
    
}