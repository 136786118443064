.textcenter{
    text-align: center;
}

.consent_container{
    padding-top: 70px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 150px;
    max-width: 100% !important;
}

.text-white{
    color: white !important;
}