.map-footer-white {
  min-height: 70px;
  background-color: white;
  border-Top-Right-Radius: 25px;
  border-top-left-radius: 25px;
  margin-left: 0;
  margin-right: 0;
  max-width: none;
  bottom: 70px;
  position: fixed;
z-index: 1000;
}

.map-footer-blue {
  min-height: 50px;
  background-color: #3A4D8F;
  border-Top-Right-Radius: 25px;
  border-top-left-radius: 25px;
  margin-left: 0;
  margin-right: 0;
  max-width: none;
  bottom: 0;
  position: fixed;
z-index: 100;
}

.map-header-blue {
  min-height: 50px;
  background-color: #3A4D8F;
  border-bottom-Right-Radius: 12px;
  border-bottom-left-radius: 12px;
  margin-left: 0;
  margin-right: 0;
  max-width: none;
  top: 0;
 // position: absolute;
z-index: 100;
// box-shadow: 0 3px 5px 2px rgba(58, 77, 143, .3);
background: linear-gradient(125deg, rgba(58,77,143,1) 0%, rgba(118,158,209,1) 100%);
//background: Radial-Gradient(#769ED1  100%, #3268B2 0%)
}
.map-footer-blue_ {
  min-height: 70px;
  background-color: white;
  margin-left: 0;
  margin-right: 0;
  max-width: none;
  bottom: 0;
  position: fixed;

}

.map-search-input {
  display: flex;
  align-items: center;
  border-radius: 13px !important;
  height: 45px;
}

.box-fixed {
  background-color: #3A4D8F;
  // border-bottom-Right-Radius: 25px;
  // border-bottom-left-radius: 25px;
  border-radius: 4px;
  color: white;
  position: fixed;
  width: 100px;
  z-index: 1;
  top: 30px;
  right: 30px;

}

.price-tag {
  background-color: #4285F4;
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 14px;
  padding: 10px 15px;
  position: relative;
}

.price-tag::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, 0);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #4285F4;
}

.map-typog {
  position: absolute;
  line-Height: 1;
  color: #fff;
  top: 1.2em;
  font-Size: 11px !important;
}

.map-typog-selected {
  position: absolute;
  line-Height: 1;
  color: #3A4D8F;
  top: 1.2em;
  font-weight: bold !important;
  font-Size: 11px !important;
}

.map-root {
  position: relative;
  display: inline-flex;
  justify-Content: center, ;
  align-Items: center;
   padding-bottom: 20px;
}

.map-icon-selected {
  color: white;
  font-size: 40px;
}

.map-icon {
  color: #3A4D8F;
  font-size: 40px;

}

.map-info-box {
 margin-top: -16px;
 margin-left: -16px;
}

.parking-char {
  text-Align: center;
  margin: 0;
  color: #455D80;
  font-Size: 40px !important;
  padding: 0;
  font-weight: bold !important;
}
.parking-plate-num{
  text-Align: center;
  margin: 0;
  color: #455D80;
  font-Size: 30px !important;
  padding: 0.2em;
  font-weight:500 !important;
}
.blur-cont{
 // background-color: rgba(255,255,255,0.5);
  filter: blur(8px);
 
}
.icon_white{
  color: white;

}
.icon_grey{
  color: gray;

}

.marker-l{
  margin-bottom: -20px;
}

.public-form{
  touch-action: none;
  min-height: 100vh;
}

.font16 input{
  font-size: 16px !important;
}

.public-dialog div .MuiPaper-root{
  padding: 0;
  border-radius: 0 !important;
  touch-action: none;
}
.public-dialog div .MuiPaper-root .border-card{
  border-radius: 10px !important;
}

.inputLots{
  width: inherit;
  margin-bottom: 10px;
}

.inputLots div fieldset{
  border: none;
 
}
.inputLots input{
  border-radius: 20px !important;
  font-size: 16px !important;
  padding: 10px;
}

.map-seach-lots{
  border-radius: 12px;
  height: 38px;
  background-color: #F5F6FA;
  width: 100%;
  color: rgba(35, 49, 68, 0.5);
  border: none;
}

