.inputAccount input {
    border-radius: 12px;
    background-color: #F5F6FA;
    font-size: 16px !important;
}

.inputAccount fieldset {
    border-color: #F5F6FA !important;
}

.inputAccount div {
    background-color: #F5F6FA;
}

.bottom-button{
    margin-left: 0;
    margin-right: 0;
    bottom: 0px;
    position: absolute;
    width: 100%;
}

.cancel-logout{
    border-radius: 12px;
    height: 48px;
    background-color: white;
    width: 100%;
    color: rgba(35, 49, 68, 0.5);
    border: 1px solid rgba(35, 49, 68, 0.5);
    font-size: large;
}

.confirm-logout{
    border-radius: 12px;
    height: 48px;
    background-color:   #3268B2;
    width: 100%;
 
    font-size: large;
}

.logout-footer-white{
     background-color: white;
    border-Top-Right-Radius: 25px;
    border-top-left-radius: 25px;
    margin-left: 0;
    margin-right: 0;
    max-width: none;
    bottom: 0px;
    position: absolute;
}
.grey-box{
    width: 100%;
    height: 400px;
    background-color: gray;
    background: linear-gradient(180deg, white 0%, gray 100%);
}

.parking-time-info{
    font-size: 50px !important;
    color: #3268B2;
    font-weight: 900 !important;

}
.parking-footer-white{
    min-height: 70px;
    background-color: #F5F6FA;
    border-Top-Right-Radius: 25px;
    border-top-left-radius: 25px;
    margin-left: 0;
    margin-right: 0;
    max-width: none;
    bottom: 0px;
    position: fixed;
  z-index: 1000;
 
}

.parking-footer-button{
    border-radius: 12px;
    height: 48px;
    background-color: #f8c7bf;
    width: 80%;
    color: #D69F97;
    border: none;
    margin-bottom: 2px;
    box-shadow: inset 0px 2px 1px -1px rgba(0,0,0,0.2), inset 0px 1px 1px 0px rgba(0,0,0,0.14), inset 0px 1px 3px 0px rgba(0,0,0,0.12);
  }

 