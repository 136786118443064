@import "../../../assets/styles/variables";

.AppBar {
  //background: orange;
  height: 100vh;
}
.iconLeft{
  margin-left: 0px !important;
}
//.title {
//  font-family: "AlrightSans-Bold";
//  color: $secondary;
//  font-size: 18px;
//  line-height: 22px;
//  font-weight: normal;
//}
