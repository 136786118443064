@import '../booking-list/item/ExchangesBookingItem.scss';

.bookedUserName{
    background: #F7F7F7;
    padding: 3px 5px;
    font-size: 0.75em;
    display: inline-block;
    font-weight: bold;
    margin: 5px 0;
    text-align: right;
    min-width: 88px;
}

.BookedSpaceTitle{
    font-size: 1.5em;
    font-family: 'BebasNeue-Regular';
    display: flex;
    max-height: 32px;
}

.BookedItemContainer{
    height: 100%;
    min-height: 80px;
    background: $surface !important;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.05);
}