@import '../../../../assets/styles/variables';
//.spaceItemContainer {
//  height: 100%;
//  min-height: 88px;
//  background: $surface !important;
//  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.05);
//}
//.SpaceName {
//  display: flex;
//  align-items: baseline;
//}
//.NameIcon{
//  margin-left: 0.5em;
//}
//.space-name {
//  align-self: baseline;
//  .MuiListItemText-primary {
//    font-size: 2em !important;
//  }
//  .MuiListItemText-secondary {
//    font-size: 0.875em;
//    color: $text-color;
//  }
//}
.add-btn {
  border: 1px solid $borders !important;
  display: flex;
  background: $surface !important;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  width: 25px;
  height: 25px;
}

.shareBtn {
  margin-right: 1em !important; 
  border: 1px solid $borders !important;
  display: flex;
  align-items: center;
  border-radius: 12px !important;
  justify-content: center;
  padding: 0 !important;
  width: 25px;
  height: 25px;
  .shareBtn-text {
    color: $secondary;
    text-transform: none;
    font-family: 'Roboto-Regular';
    font-size: 0.75em;
  }
}

.expandClass{
  min-height: 0px !important;
  padding: 0 24px 0 0 !important;
  
  .Mui-expanded{
    min-height: 0px !important;
  }
  .MuiAccordionSummary-content{
    margin: 0px !important;
  }
  
}

.disableElevation1 {
  box-shadow: 0px 0px 0px 0px green,
  0px 0px 0px 0px green,
  0px 0px 0px 0px green !important;
}

div.MuiButtonBase-root.MuiAccordionSummary-root.expandClass.Mui-expanded{
      min-height: 0px !important;
    }
  // }

.listItemExpand{
  padding: 0 24px 0 0 !important;

  font-size: 0.875em !important;
  //  color: $text-color;
    border: none !important;
}

.listExpand{
  padding: 0 0 0 0 !important;
}