@import '../../../../assets/styles/variables';

.input-label {
  transform: translate(2px, -6px) scale(0.8125) !important;
  transform-origin: top left;
  &.error {
    color: $error;
  }
}

.input__text {
  height: 40px;	
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $secondary;
  background-color: $surface;
  //box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.1);
  width: 100%;
  color: $text-color;
  &.Mui-error {
    border: 1px solid $error;
  }

}

.input__text input{
  font-size: 16px !important;
}

.input-error {
  color: $error;
  width: 100%;
  position: absolute;
  min-height: 24px;
  transform: translatey(56px);
}
.input-errormsg {
  color: $error;
  margin-left: 3px;
  font-size: 0.75em;
}