@import '../../../../assets/styles/variables';

.item {
  height: 72px;
  background: $surface !important;
}
.item-shadow {
  height: 72px;
  background: $surface !important;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.05);
}
.changeBtn {
  margin-right: 1em !important; 
  border: 1px solid $borders !important;
  display: flex;
  align-items: center;
  border-radius: 12px !important;
  justify-content: center;
  padding: 0 !important;
  width: 25px;
  height: 25px;
  .changeBtn-text {
    color: $text-secondary;
    text-transform: none;
    font-family: 'Inter-UI-Regular';
    font-size: 0.75em;
  }
}

.delete-btn {
  border: 1px solid $borders !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  width: 25px;
  height: 25px;
}