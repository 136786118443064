@import '../../assets/styles/variables';

.ActiveHistoryContainer {
  top: 68px;
  position: relative;
  width: 100%;
  background: $background;
}
.ActiveHistoryList{
  position: relative;
  height: calc(100vh);
}