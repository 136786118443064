@import "../../../../assets/styles/variables";

.itemIcon{
    direction: rtl;
}

.ListItem {
    min-width: 304px;
    display: block;
    text-decoration: none !important;
    color: $text-color !important;
  }