@import '../../../../assets/styles/variables';
.login-container {
  background: $surface;
  height: 100vh;
}
.login-head {
  height: 20vh;
  background: $secondary;
  z-index: 0;

}
.login-title {
  text-align: center;
  font-family: "AlrightSans-Bold";
  font-weight: bold;
  letter-spacing: 3px;
  font-size: 1.5em;
  line-height: 1.5em;
  color: $secondary;
}
.login-head-image {
  position: center;
  //z-index: -1;
  top: 0;
  left: 0;
  //height: 30vh;
  width: 50%;
 // object-fit: cover;
}
.login-form {
  height: 60vh;
}
.login-btn {
  margin-top: 1em !important;
}
.btn {
  // margin: 0.5em auto !important;
  border-radius: 24px !important;
  background: $secondary !important;
  padding: 0 !important;

}
.btn-wrapper {
  position: relative;
}
.buttonProgress {
  position: absolute;
  color: $success;
  top: 50%;
  left: 50%;
  margin-top: -12;
  margin-left: -12;
}
.parking-icon {
  width: 74px;
  height: 103px;
  border: 8px solid $surface;
}
.btn-text {
  font-family: 'Inter-UI-Bold';
  text-transform: none;
  line-height: 1em;
  color:  $surface;
  padding: 1em 0;
}
.parking-icon {
  font-family: 'Inter-UI-Bold';
  color: $surface;
  font-size: 4.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.link-centered {
  text-align: center;
  font-family: 'Inter-UI-Regular';
  text-decoration: none !important;
}
.MuiCircularProgress-colorPrimary{
  color: $secondary !important;
}
.loginCar{
  font-size: 3.5rem !important;
  color: $secondary;
}
.login-load-container{
  position: relative;
  top: 120px;
}
.tc-container {
  position: absolute;
  bottom: 0;
  padding-bottom: 12px;
}