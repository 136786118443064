@import '../../../../assets/styles/variables';

.input {
  height: 40px;	
  width: 97%;
  margin: 1em 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $borders;
  background-color: $surface;
  box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.1);
}
.input__text {
  width: 100%;
  padding-left: 0.75em;
  color: $text-color;
}