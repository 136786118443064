@import '../../../assets/styles/variables';

.list-head {
  height: 67px;
}
.LoadMore {
  background: $surface !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
}
.add-btn {
  border: 1px solid $borders !important;
  display: flex;
  background: $surface !important;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  width: 25px;
  height: 25px;
}