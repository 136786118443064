.MuiOutlinedInput-notchedOutline{
    border-radius: 4px !important;
}

.authInput{
    border-radius: 12px;
    height: 48px;
}

.authInput input {
    background-color: #F5F6FA;
    font-size: 16px !important;
}

.authInput fieldset {
    border-color: #F5F6FA !important;
}

.authInput div {
    background-color: #F5F6FA;
}

.authButton{
    border-radius: 12px;
    height: 48px;
    background-color: #3268B2;
    width: 100%;
    text-transform: none;
}