
@import '../../assets/styles/variables';
.ExchangesContainer, .ExchangesHistoryContainer {
  top: 68px;
  position: relative;
  width: 100%;
  background: $background;
}
.ExchangesHistoryContainer{
  top: 48px;
}

.ExchangesForm {
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 1em;
}
.ExchangesList{
  position: relative;
  height: calc(100vh);
}
.ExpansionPanel{
  clear: both;
}