@import "../../../assets/styles/variables";

.ReportsFormContainer {
  //box-shadow: 0 1px 4px 0 rgba(0,0,0,0.05);
  position: relative;
  top: 70px;
  padding-bottom: 1em;
  padding: 1em;
 // background: $surface;
}
.monthlyReportsContainer {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  top: 54px;
  padding-bottom: 1em;
  padding: 1em;
  background: $surface;
}