@import '../../assets/styles/variables';

.availableSitesContainer {
  top: 120px;
  overflow: auto;
  // height: calc(100vh - 186px);
  background: $background;
  width: 100vw;
}

.availableSitesContainerListItem {
  background: $surface !important;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
}

.availableSitesListItemTitle {
  font-size: 1em !important;
  font-family: 'Inter-UI-Regular';
}

.button-edit-margin {
  margin-right: 0px !important;
}

.availableSitesFormContainer {
  display: flex;
  justify-content: space-around;
}

.availableSitesSelectInputError {
  transform: translatey(0px) !important;
}
