.inputShadow input {
    border-radius: 12px;
    background-color: #F5F6FA;
}

.inputShadow fieldset {
    border-color: #F5F6FA !important;
}

.inputLabelStyle {
    color: #233144;
    font-size: 22px;
    font-weight: 500;
    font-family: 'Roboto-Bold' !important;
}

.inputShadow div {
    background-color: #F5F6FA;
}

.card-form-container {
    padding-top: 5em;
    padding-bottom: 1em;
    background: #FFFFFF;
    padding-left: 2em;
    padding-right: 2em;
}

.buttonStyle {
    border-radius: 12px;
    height: 51px;
}

.CreditCardBackround {
    left: 0;
    width: 100%;
}