@import '../../assets/styles/variables';

.adminstrationContainer{
    top: 56px;
    position:relative;
  width:  '90%';
   
}

.adminstrationContainerListItem {
     height: 88px;
  background: $surface !important;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.05);
}

.adminstrationContainerListItem_ {
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
   
}


.adminstrationListItemText{
    align-self: baseline;
  .MuiListItemText-primary {
    font-size: 2em !important;
  } 
  .MuiListItemText-secondary {
    font-size: 0.875em;
    color: $text-color;
  } 
}

.container {
    text-align: center;
    padding: 15px;
    margin: 10px auto;
    position:relative;
    top: 54px;
}

.csv-input {
    padding: 10px;
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
}

.container p {
    color:green;
}

.arrList {
    padding-top: 10px;
    color: red;
    padding-left: 10px;
}

.arrList p {
    margin-bottom: 8px;
    margin-top: 8px;
}

.doneCount {
    text-align: left;
}

