@import "../../../../assets/styles/variables";

.MuiPickersBasePicker-container{
    align-items: center;
    border-radius: 0 0 16px 16px;
}

.territoryTitle_container{
    background: $surface;
    margin: 0px 10px 0 0;
    padding: 10px 10px;
   // padding-top: 1em;
}

.territoryTitle_label{
    font-size: 1.7em !important;
    font-family: 'BebasNeue-Regular' !important;
    text-align: center;
}

.monthInformation_container{
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);

    border-radius: 25px;
}

.MuiPickersStaticWrapper-staticWrapperRoot{
    border-radius: 0 0 16px 16px;
}

.legendList{
    padding-left: 2em !important;
}

.legendListItem{
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}

.legend_container{
    background: $surface;
    padding-top: 1em;
    border-radius: 0 0 16px 16px;
}

div.MuiPickersSlideTransition-transitionContainer.MuiPickersCalendar-transitionContainer{
    min-height: 256px;
}
