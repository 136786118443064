@import '../../../../assets/styles/variables';

.SubscriptionItemContainer {
  height: 88px;
  background: $surface !important;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.05);
}
.SubscriptionTimeBlock {
  margin: 0 1em 0 0 ;
  vertical-align: top;
}
.SubscriptionTimeText {
  background: $background;
  margin: 0.5em 0 !important;
  font-size: 0.75em;
  padding: 0.25em 0.75em;
  font-family: 'Inter-UI-Bold';
  white-space: nowrap;
}
.SubscriptionSpaceTitle {
  font-size: 2em;
  font-family: 'BebasNeue-Regular';
}
.SubscriptionSpaceSubtitle {
  font-size: 0.875;
  font-family: 'Inter-UI-Regular';
}
.item-shadow {
  height: 72px;
  background: $surface !important;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.05);
}
.changeBtn {
  margin-right: 1em !important; 
  border: 1px solid $borders !important;
  display: flex;
  align-items: center;
  border-radius: 12px !important;
  justify-content: center;
  padding: 0 !important;
  width: 25px;
  height: 25px;
  .changeBtn-text {
    color: $text-secondary;
    text-transform: none;
    font-family: 'Inter-UI-Regular';
    font-size: 0.75em;
  }
}

.delete-btn {
  border: 1px solid $borders !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  width: 25px;
  height: 25px;
}