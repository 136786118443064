
.labelStatusText{
    font-family: 'Inter-UI-Regular' !important;
    font-size: 16px !important;
    font-weight: bolder !important;
    color: #27348A;
}

.status_div{
    width: 96%;
}

.message_container{
    padding-top: .5em;
    padding-bottom: .5em;
}

.TerrtoryStatusTitle {
    font-size: 1.7em;
    font-family: 'BebasNeue-Regular';
  }

