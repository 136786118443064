@import '../../../assets/styles/variables';

.ActiveHistoryItemContainer {
  height: 100%;
  min-height: 88px;
  background: $surface !important;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.05);

}
.ActiveHistorySpaceSubtitle {
  font-size: 0.875;
  font-family: 'Inter-UI-Regular';
}
.type {
  font-family: Roboto-Medium;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: #3A4D8F;
}
.price {
  font-family: Roboto-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 28px;
  color: #3A4D8F;
}